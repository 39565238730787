export default {
  show: 'Show',
  entries: 'organizations',
  previous: 'Previous',
  next: 'Next',
  showing: 'Showing',
  showingTo: 'to',
  showingOf: 'of',
  showingEntries: 'organizations'
}
