import { createRouter, createWebHashHistory } from "vue-router";


const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../pages/Home.vue"),
    meta: {},
  },
  {
    path: "/social",
    name: "Social",
    component: () => import("../pages/SocialHome.vue"),
    meta: {},
  },
  {
    path: "/charities",
    name: "Charities",
    component: () => import("../pages/CharitiesHome.vue"),
    meta: {},
  },


];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
