/* eslint-disable vue/multi-word-component-names */
import { createApp, reactive, h } from "vue";
// import VueFacebookPage from "vue-facebook-page";
import { createMetaManager } from 'vue-meta'

import PrimeVue from "primevue/config";
import App from "./App.vue";

// import axios from 'axios';
// import VueAxios from 'vue-axios';
import router from "./router";
//import { createPinia } from "pinia";
//Primevue components
 import AutoComplete from "primevue/autocomplete";
// import Accordion from "primevue/accordion";
// import AccordionTab from "primevue/accordiontab";
// import Avatar from "primevue/avatar";
// import AvatarGroup from "primevue/avatargroup";
// import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import Button from "primevue/button";
// import Breadcrumb from "primevue/breadcrumb";
// import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Dataset from "./components/Dataset.vue";
import DatasetInfo from "./components/DatasetInfo.vue";
import DatasetItem from "./components/DatasetItem.vue";
import DatasetPager from "./components/DatasetPager.vue";
import DatasetSearch from "./components/DatasetSearch.vue";

import DatasetShow from "./components/DatasetShow.vue";
// import CascadeSelect from "primevue/cascadeselect";
// import Carousel from "primevue/carousel";
// import Chart from "primevue/chart";
// import Checkbox from "primevue/checkbox";
// import Chip from "primevue/chip";
// import Chips from "primevue/chips";
// import ColorPicker from "primevue/colorpicker";
// import Column from "primevue/column";
// import ConfirmDialog from "primevue/confirmdialog";
// import ConfirmPopup from "primevue/confirmpopup";
// import ConfirmationService from "primevue/confirmationservice";
// import ContextMenu from "primevue/contextmenu";
// import DataTable from "primevue/datatable";
// import DataView from "primevue/dataview";
// import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import Dialog from "primevue/dialog";
// import Divider from "primevue/divider";
// import Dropdown from "primevue/dropdown";
// import Fieldset from "primevue/fieldset";
// import FileUpload from "primevue/fileupload";
// // import FullCalendar from "primevue/fullcalendar";
// import Galleria from "primevue/galleria";
// import Image from "primevue/image";
import InlineMessage from "primevue/inlinemessage";
// import Inplace from "primevue/inplace";
// import InputSwitch from "primevue/inputswitch";
// import InputText from "primevue/inputtext";
// import InputMask from "primevue/inputmask";
// import InputNumber from "primevue/inputnumber";
// import Knob from "primevue/knob";
// import Listbox from "primevue/listbox";
// import MegaMenu from "primevue/megamenu";
// import Menu from "primevue/menu";
// import Menubar from "primevue/menubar";
import Message from "primevue/message";
// import MultiSelect from "primevue/multiselect";
// import OrderList from "primevue/orderlist";
// import OrganizationChart from "primevue/organizationchart";
// import OverlayPanel from "primevue/overlaypanel";
import Paginator from "primevue/paginator";
// import Panel from "primevue/panel";
// import PanelMenu from "primevue/panelmenu";
// import Password from "primevue/password";
// import PickList from "primevue/picklist";
// import ProgressBar from "primevue/progressbar";
import Rating from "primevue/rating";
// import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
// import SelectButton from "primevue/selectbutton";
import ScrollPanel from "primevue/scrollpanel";
// import ScrollTop from "primevue/scrolltop";
// import Skeleton from "primevue/skeleton";
// import Slider from "primevue/slider";
// import Sidebar from "primevue/sidebar";
// import SplitButton from "primevue/splitbutton";
// import Splitter from "primevue/splitter";
// import SplitterPanel from "primevue/splitterpanel";
import StyleClass from "primevue/styleclass";
// import Steps from "primevue/steps";
// import TabMenu from "primevue/tabmenu";
// import TieredMenu from "primevue/tieredmenu";
// import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
// import ToastService from "primevue/toastservice";
// import Toolbar from "primevue/toolbar";
// import TabView from "primevue/tabview";
// import TabPanel from "primevue/tabpanel";
// import Tag from "primevue/tag";
// import Timeline from "primevue/timeline";
// import ToggleButton from "primevue/togglebutton";
import Tooltip from "primevue/tooltip";
// import Tree from "primevue/tree";
// import TreeSelect from "primevue/treeselect";
// import TreeTable from "primevue/treetable";
// import TriStateCheckbox from "primevue/tristatecheckbox";

import CodeHighlight from "./AppCodeHighlight";
//other 3rd party libraries
import twitter from "vue-twitter";

//Styles
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "../node_modules/prismjs/themes/prism-coy.css";
require("./App.scss");
// import "./App.scss"

router.beforeEach(function(to, from, next) {
  window.scrollTo(0, 0);
  next();
});

export const app = createApp({
  created() {},

  computed: {
    ViewComponent() {
      switch (this.$route.meta.parentcontainer) {
        case "AppBare":
          return App;

        default:
          return App;
      }
    },
  },
  render() {
    return h(this.ViewComponent);
  },
});

app.use(PrimeVue, { ripple: true });
app.use(router);
app.use(twitter);
app.use(createMetaManager())


// app.use(VueFacebookPage, "354777303304194");

app.config.globalProperties.$appState = reactive({
  colorScheme: "light",
  inputStyle: "outlined",
});
//app.use(createPinia());

app.directive("tooltip", Tooltip);
app.directive("badge", BadgeDirective);
app.directive("ripple", Ripple);
app.directive("code", CodeHighlight);
app.directive("styleclass", StyleClass);

// app.component("Accordion", Accordion);
// app.component("AccordionTab", AccordionTab);
app.component("AutoComplete", AutoComplete);
// app.component("Avatar", Avatar);
// app.component("AvatarGroup", AvatarGroup);
// app.component("Badge", Badge);
// app.component("Breadcrumb", Breadcrumb);
app.component("Button", Button);
// app.component("Calendar", Calendar);
app.component("Card", Card);
// app.component("Carousel", Carousel);
// app.component("CascadeSelect", CascadeSelect);
// // app.component("Chart", Chart);
// app.component("Checkbox", Checkbox);
// app.component("Chip", Chip);
// app.component("Chips", Chips);
// app.component("ColorPicker", ColorPicker);
// app.component("Column", Column);
// app.component("ConfirmDialog", ConfirmDialog);
// app.component("ConfirmPopup", ConfirmPopup);
// app.component("ContextMenu", ContextMenu);
// app.component("DataTable", DataTable);
// app.component("DataView", DataView);
// app.component("DataViewLayoutOptions", DataViewLayoutOptions);
app.component("Dialog", Dialog);
// app.component("Divider", Divider);
// app.component("Dropdown", Dropdown);
// app.component("Fieldset", Fieldset);
// app.component("FileUpload", FileUpload);
// // app.component("FullCalendar", FullCalendar);
// app.component("Galleria", Galleria);
// app.component("Image", Image);
app.component("InlineMessage", InlineMessage);
// app.component("Inplace", Inplace);
// app.component("InputMask", InputMask);
// app.component("InputNumber", InputNumber);
// app.component("InputSwitch", InputSwitch);
// app.component("InputText", InputText);
// app.component("Knob", Knob);
// app.component("Listbox", Listbox);
// app.component("MegaMenu", MegaMenu);
// app.component("Menu", Menu);
// app.component("Menubar", Menubar);
app.component("Message", Message);
// app.component("MultiSelect", MultiSelect);
// app.component("OrderList", OrderList);
// app.component("OrganizationChart", OrganizationChart);
// app.component("OverlayPanel", OverlayPanel);
app.component("Paginator", Paginator);
// app.component("Panel", Panel);
// app.component("PanelMenu", PanelMenu);
// app.component("Password", Password);
// app.component("PickList", PickList);
// app.component("ProgressBar", ProgressBar);
// app.component("RadioButton", RadioButton);
app.component("Rating", Rating);
// app.component("SelectButton", SelectButton);
app.component("ScrollPanel", ScrollPanel);
// app.component("ScrollTop", ScrollTop);
// app.component("Slider", Slider);
// app.component("Sidebar", Sidebar);
// app.component("Skeleton", Skeleton);
// app.component("SplitButton", SplitButton);
// app.component("Splitter", Splitter);
// app.component("SplitterPanel", SplitterPanel);
// app.component("Steps", Steps);
// app.component("TabMenu", TabMenu);
// app.component("TabView", TabView);
// app.component("TabPanel", TabPanel);
// app.component("Tag", Tag);
// app.component("Textarea", Textarea);
// app.component("TieredMenu", TieredMenu);
// app.component("Timeline", Timeline);
app.component("Toast", Toast);
// app.component("Toolbar", Toolbar);
// app.component("ToggleButton", ToggleButton);
// app.component("Tree", Tree);
// app.component("TreeSelect", TreeSelect);
// app.component("TreeTable", TreeTable);
// app.component("TriStateCheckbox", TriStateCheckbox);

//App use for 3rd party
app.component("Dataset", Dataset);
app.component("DatasetItem", DatasetItem);
app.component("DatasetInfo", DatasetInfo);
app.component("DatasetPager", DatasetPager);
app.component("DatasetSearch", DatasetSearch);
app.component("DatasetShow", DatasetShow);

//Initialize Amplitude
import amplitude from "amplitude-js";
app.config.globalProperties.$amplitude = amplitude;
// var ampKey = process.env.NODE_ENV === "production" ? "" : "";
var ampKey =
   process.env.NODE_ENV === "production"
      ? "9c33832750f5d93e1d0294b481e2d7a6"
      : "";
amplitude.getInstance().init(ampKey, null, {includeReferrer: true, includeUtm: true, includeGclid: true});
app.provide('amplitude', amplitude)
/////////////////////////////////

app.mount("#app");
