<script setup>
import { useRouter } from "vue-router";
import { inject, ref } from "vue";
import { useMeta } from 'vue-meta'
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
let amplitude = inject("amplitude");
const router = useRouter();

useMeta({
      title: 'Home',
      htmlAttrs: { lang: 'en', amp: true }
    })

const showDialog = ref(false);
const dialogBody = ref("");
const dialogHeader = ref("");

function donate() {
  dialogHeader.value = "Coming soon";
  dialogBody.value =
    "Thank you for your interest in donating.  We are in the final stages of vetting Ukraine-related charities.  Please check back on 5/16/2022.";
  showDialog.value = true;

  amplitude.getInstance().logEvent("Top nav - click donate", {
    route: router.currentRoute._rawValue.path
  });
}

function learn() {
  dialogHeader.value = "Coming soon";
  dialogBody.value =
    "We are currently updating our learning resources page.  Please check back shortly.";
  showDialog.value = true;

  amplitude.getInstance().logEvent("Top nav - learn", {
    route: router.currentRoute._rawValue.path
  });
}
</script>

<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content} | Zelensky` : `Zelensky` }}</template>
  </metainfo>
  <div class="min-h-screen flex flex-column surface-ground">
    <Dialog
      :header="dialogHeader"
      v-model:visible="showDialog"
      :style="{width: '50vw'}"
      :breakpoints="{'960px': '75vw', '640px': '100vw'}"
    >{{dialogBody}}</Dialog>
    <div
      class="surface-overlay px-6 flex justify-content-between relative lg:static"
      style="min-height: 84px"
    >
      <img
        src="images/emblem.svg"
        alt="Image"
        height="40"
        class="cursor-pointer mr-0 lg:mr-6 align-self-center"
        @click="router.push('/')"
      />
      <a
        v-ripple
        class="cursor-pointer block lg:hidden align-self-center text-700 mt-1 p-ripple"
        v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }"
      >
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <div
        class="flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none"
      >
        <ul class="list-none p-0 m-0 flex select-none flex-column lg:flex-row">
          <li>
            <a
              v-ripple
              class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
              @click="router.push('/')"
              style="text-color:#2F3F5F"
            >
              <span style="text-color:#2F3F5F">Home</span>
            </a>
          </li>
          <li>
            <a
              v-ripple
              class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
              @click="router.push('/social')"
            >
              <span style="text-color:#2F3F5F">Social</span>
            </a>
          </li>
          <li>
            <a
              v-ripple
              class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
              @click="learn"
            >
              <span style="text-color:#2F3F5F">Learn</span>
            </a>
          </li>
          <li></li>
        </ul>
        <ul
          class="list-none p-0 m-0 flex select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none"
        >
          <li></li>

          <li class="border-top-1 surface-border lg:border-top-none">
            <a
              v-ripple
              class="flex px-6 p-3 h-full lg:px-3 lg:py-2 align-items-center border-left-2 lg:border-bottom-2 lg:border-left-none border-transparent hover:border-blue-500 font-medium cursor-pointer transition-colors transition-duration-150 p-ripple"
              @click="router.push('/charities')"
            >
              <span class="text-4xl" style="text-color:#2F3F5F">Donate</span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="p-5 flex flex-column flex-auto bg-white">
      <div class="align-self-center" style="max-width: 1478px">
        <!-- <div class="m-4 align-self-center px-4 py-8 md:px-6 lg:px-8">  -->
        <router-view :key="$route.fullPath" v-slot="{ Component }">
          <component :is="Component" />
        </router-view>
      </div>
    </div>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
